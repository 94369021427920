<template>
  <main class="creator-feed">
    <section class="sectionFeed">
      <HomeFeed :creator="creator" />
    </section>
  </main>
</template>

<script>
export default {
  components: {
    HomeFeed: () => import("../home/HomeFeed.vue"),
  },
  data: function() {
    return {
      creator: false,
    };
  },
  methods: {
    startProfile: async function() {
      this.isLoading(true);
      const search = this.$api.get(`${this.$apiHost}creator/search?q=${this.$creator ? this.$creator : ""}`);
      search.then((response) => {
        if (response.data?.creators?.length) {
          this.creator = response.data.creators[0];
          this.$store.dispatch("payload", { method: "append", lastCretor: this.creator.user });
          this.$store.commit("setCurrentCreator", this.creator);
        } else {
          this.creator = "";
          this.$store.commit("setCurrentCreator", null);
        }
        this.isLoading(false);
      });
      search.catch((error) => {
        this.isLoading(false);
        this.alert(Go.getErrorMessage(error));
      });
    },
  },
  mounted: async function() {
    this.startProfile();
  },
  watch: {
    $route(to, from) {
      if (!to.path.includes(from.path) && !from.path.includes(to.path)) {
        this.startProfile();
      }
    },
  },
};
</script>

<style lang="scss">
.creator-feed {
  .line-preloading {
    position: absolute;
    overflow: hidden;
    height: 3px;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    .line {
      position: absolute;
      content: "";
      height: 3px;
      animation: increase 1.5s infinite;
      background-color: $primary_color;
    }
    @keyframes increase {
      from {
        left: -50%;
        width: 50%;
      }
      to {
        left: 100%;
        width: 30%;
      }
    }
  }
}
</style>
